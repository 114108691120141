import axios from "axios";
import _ from "./actionType";

const url = "https://testapi.bookonelocal.co.nz/api-bookone/api";

export const logout = () => (dispatch) => {
  dispatch({ type: _.logout });
};

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: _.login.pending,
  });

  const res = await axios.post(
    `${url}/user/login`,
    { username: email, password },
    {
      validateStatus: false,
    }
  );

  console.log(res);

  if (res.status !== 200) {
    console.log("failed");

    return dispatch({
      type: _.login.fail,
      payload: { errorMessage: res.data.message },
    });
  }

  dispatch({
    type: _.login.success,
    payload: {
      user: res.data.user,
      token: res.data.token,
    },
  });
};

export const clearAllError = () => (dispatch) => {
  return dispatch({
    type: _.clearAllErrors,
  });
};

export const actions = { login, logout, clearAllError };
