import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { AiFillCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../store/actions";

const SubscriptionPlan = () => {
  const [contract, setContract] = useState("");
  const [contractDate, setContractDate] = useState(0);
  const dispatch = useDispatch();
  const form = useSelector((state) => state.updateSubscribers.form);

  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: "43rem", height: "20rem", fontSize: "1rem" }}
        className="shadow-lg rounded border-0"
      >
        <Card.Body style={{ color: "#808080" }} className="fw-bolder">
          <Row>
            <Col>
              <h4 style={{ fontSize: "1.8rem" }} className="text-center">
                Subscription Plan
              </h4>
            </Col>
          </Row>

          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Contract</Form.Label>
                  <InputGroup>
                    <InputGroup.Radio aria-label="Radio button for following text input" />
                    <FormControl
                      placeholder="Monthly Subscription"
                      aria-label="Text input with radio button"
                      value={contract}
                      onChange={(e) => setContract(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Radio aria-label="Radio button for following text input" />
                    <FormControl
                      placeholder="Sales Commission"
                      aria-label="Text input with radio button"
                      value={contract}
                      onChange={(e) => setContract(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contract start date</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="dd/mm/yy"
                      value={contractDate}
                      onChange={(e) => setContractDate(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Row>
                <Col>
                  <Button>Prev</Button>
                  <Button
                    onClick={() => {
                      dispatch(actions.updateSubscribers.update(form));
                    }}
                  >
                    Next
                  </Button>
                </Col>
                <Col>
                  <Link to="/subscriptions">
                    <Button>Quit</Button>
                  </Link>
                </Col>
              </Row>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SubscriptionPlan;
