import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Nav, Col, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../../layout/footer";

import * as actions from "../../../../store/actions";

const filters = [
  "Lender",
  "Bundle Feat",
  "Financial Info",
  "Product",
  "Specials",
];

const LoanTable = () => {
  const dispatch = useDispatch();

  const [selectCheck, setSelectCheck] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  const [selectedLender, setSelectedLender] = useState(null);

  const currentPage = useSelector((store) => {
    return (
      (store.loanPackage.lendersIdsByFilter[selectedFilter] &&
        store.loanPackage.lendersIdsByFilter[selectedFilter].selectedPage) ||
      1
    );
  });
  console.log(currentPage);

  const currentPageLoading = useSelector((store) => {
    return (
      store.loanPackage.lendersIdsByFilter[selectedFilter] &&
      store.loanPackage.lendersIdsByFilter[selectedFilter].loadingByPage[
        currentPage
      ]
    );
  });

  const totalDataCount = useSelector((store) => {
    return (
      store.loanPackage.lendersIdsByFilter[selectedFilter] &&
      store.loanPackage.lendersIdsByFilter[selectedFilter].totalCount
    );
  });

  const lenderList = useSelector((store) => store.loanPackage.lendersList);

  const lenderIdsToShow = useSelector((store) => {
    return (
      store.loanPackage.lendersIdsByFilter[selectedFilter] &&
      store.loanPackage.lendersIdsByFilter[selectedFilter].dataByPage[
        currentPage
      ]
    );
  });

  const selectPage = (pageNum) => {
    dispatch(actions.loanPackage.selectPage(selectedFilter, pageNum));
  };

  const pageSize = 10;

  const [totalPagesCount, setTotalPagesCount] = React.useState();

  const checkboxHandler = () => {
    setSelectCheck(!selectCheck);
  };

  React.useEffect(() => {
    !lenderIdsToShow &&
      !currentPageLoading &&
      dispatch(actions.loanPackage.searchLenders(currentPage, selectedFilter));
  }, [currentPage, selectedFilter]);

  React.useEffect(() => {
    setTotalPagesCount(
      totalDataCount &&
        (totalDataCount / pageSize).toString().split(".").length > 1
        ? Number((totalDataCount / pageSize).toFixed(0)) + 1
        : totalDataCount / pageSize
    );
  }, [totalDataCount, selectedFilter]);

  console.log({ totalDataCount, totalPagesCount, currentPage });

  return (
    <Container className="d-flex flex-column" id="content-wrapper">
      <Container id="content">
        <Container className="container-fluid">
          <div>
            <Container className="tab-content">
              <div className="tab-pane active" role="tabpanel" id="tab-1">
                <Row className="row">
                  <Col className="col">
                    <Card className="card shadow">
                      <Card className="card-header py-3">
                        <div className="hstack gap-2">
                          <button className="btn" type="button">
                            ANZ
                          </button>
                          <button className="btn" type="button">
                            Westpac
                          </button>
                          <button className="btn" type="button">
                            CBA
                          </button>
                          <button className="btn" type="button">
                            Macquarie
                          </button>
                        </div>
                      </Card>
                      <Card.Body className="card-body">
                        <div
                          className="table-responsive table mt-2"
                          id="dataTable"
                          role="grid"
                          aria-describedby="dataTable_info"
                        >
                          <Table className="table-sm text-secondary">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Bandle Name</th>
                                <th>Status</th>
                                <th>Start date</th>
                              </tr>
                            </thead>{" "}
                            <tbody>
                              {lenderIdsToShow &&
                                lenderIdsToShow.map((id, i) => {
                                  const each = lenderList[id];

                                  const trialEndDate = new Date(
                                    each.trialEndDate
                                  );
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <input
                                          type="radio"
                                          id="selectCheck"
                                          onChange={() => {
                                            if (selectedLender == each.id) {
                                            } else {
                                              setSelectedLender(each.id);
                                            }
                                          }}
                                          checked={selectedLender == each.id}
                                        />
                                      </td>
                                      <td>
                                        {each.firstName} {each.lastName}
                                      </td>
                                      <td>{each.status}</td>
                                      <td>{`${trialEndDate.getFullYear()}/${
                                        trialEndDate.getMonth() + 1
                                      }/${trialEndDate.getDate()}`}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                        <Row className="row">
                          <Col className="col-md-6 align-self-center">
                            <p
                              id="dataTable_info"
                              className="dataTables_info"
                              role="status"
                              aria-live="polite"
                            >
                              Showing{(currentPage - 1) * pageSize + 1} to{" "}
                              {currentPage * pageSize} of{totalDataCount}
                            </p>
                          </Col>
                          <Col className="col-md-6">
                            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                              <Nav className="pagination">
                                <Nav.Item
                                  className={`page-item${
                                    currentPage <= 1 && "diabled"
                                  }`}
                                  onClick={(e) => {
                                    selectPage(currentPage - 1);
                                    e.preventDefault();
                                  }}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-lable="preveios"
                                  >
                                    <span aria-hidden="true">«</span>
                                  </Link>
                                </Nav.Item>
                                {/*{pageNumber}*/}
                                {totalPagesCount &&
                                  range(1, totalPagesCount).map((pageNum, i) =>
                                    i > currentPage - 5 &&
                                    i < currentPage + 5 ? (
                                      <Nav.Item
                                        className={`page-item ${
                                          currentPage == pageNum && "active"
                                        }`}
                                        onClick={() => {
                                          selectPage(pageNum);
                                        }}
                                      >
                                        <Link className="page-link" to="#">
                                          {pageNum}
                                        </Link>
                                      </Nav.Item>
                                    ) : null
                                  )}
                                <Nav.Item
                                  onClick={(e) => {
                                    selectPage(currentPage + 1);
                                    e.preventDefault();
                                  }}
                                  className="page-item"
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">»</span>
                                  </Link>
                                </Nav.Item>
                              </Nav>
                            </nav>
                          </Col>
                        </Row>{" "}
                        <Row>
                          <Col>
                            <Button
                              disabled={selectedLender == null}
                              className="p-0"
                            >
                              <Link
                                className="btn btn-primary btn-sm d-none d-sm-inline-block"
                                role="button"
                                to="/loanform"
                              >
                                <i className="fas fa-search fa-sm text-white-50"></i>
                                &nbsp;Manage
                              </Link>
                            </Button>
                          </Col>
                          <Col>
                            <Link
                              className="btn btn-primary btn-sm d-none d-sm-inline-block"
                              role="button"
                              to="#"
                            >
                              <i className="fas fa-plus fa-sm text-white-50"></i>
                              &nbsp;New
                            </Link>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    {/* <FormScreen /> */}
                  </Col>
                </Row>
              </div>
              <div className="tab-pane" role="tabpanel" id="tab-2">
                <p>Content for tab 2.</p>
              </div>
              <div className="tab-pane" role="tabpanel" id="tab-3">
                <p>Content for tab 3.</p>
              </div>
            </Container>
          </div>
        </Container>
      </Container>
      <Footer />
    </Container>
  );
};
export default LoanTable;

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
