import types from "./actionType";

import axios from "axios";

export const selectSubscriber = () => {};
export const removeSelectedSubscriber = () => {};

export const getSubscriber = (id, token) => async (dispatch) => {
  dispatch({
    type: types.getSubscriber.pending,
    payload: {},
  });

  const res = await axios.get("http://localhost:5000/subscribers", {
    params: {
      pageSize: 1,
    },
    validateStatus: false,
  });
  console.log(res);
  if (res.status !== 200) {
    dispatch({
      type: types.getSubscriber.fail,
      payload: { errorMessage: "failled to load data" },
    });
  }

  dispatch({
    type: types.getSubscriber.success,
    payload: {
      data: res.data.data[0],
    },
  });
};

export const update = (updatedData, token) => async (dispatch) => {
  dispatch({
    type: types.update.pending,
    payload: {},
  });

  const res = await axios.get("http://localhost:5000/subscribers", {
    params: {
      pageSize: 1,
    },
    validateStatus: false,
  });
  console.log(res);
  if (res.status !== 200) {
    dispatch({
      type: types.update.fail,
      payload: { errorMessage: "failled to load data" },
    });
  }

  dispatch({
    type: types.update.success,
    payload: {
      updatedSubscriber: { ...res.data.data[0], id: updatedData.id },
    },
  });
};

export const updateFrom = (newData) => (dispatch) => {
  dispatch({ type: types.updateFrom, payload: { newData } });
};

export const createNewSubscriber = () => {};

export default {
  getSubscriber,
  update,
  createNewSubscriber,
  selectSubscriber,
  removeSelectedSubscriber,
};
