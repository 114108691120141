import _ from "./actionType";

const initialState = {
  selectedSubscriber: {},
  getSubscriberLoading: false,

  form: {},

  updateSubscriberLoading: false,

  createNew: true,
  errorMessage: null,
};

export default function updateSubscribers(state = initialState, action) {
  console.log(action);
  if (!action.payload) return state;
  const { selectedSubscriberData } = action.payload;
  switch (action.type) {
    // case _.selectSubscriber:
    //   return {
    //     ...state,
    //     createNew: false,
    //     selectedSubscriber: selectedSubscriberData,
    //   };

    case _.getSubscriber.pending:
      return {
        ...state,
        getSubscriberLoading: true,
      };
    case _.getSubscriber.success:
      return {
        ...state,
        getSubscriberLoading: true,
        selectedSubscriber: { ...action.payload.data },
        form: { ...action.payload.data },
      };
    case _.getSubscriber.fail:
      return {
        ...state,
        getSubscriberLoading: false,
        errorMessage: action.payload.errorMessage,
      };

    case _.update.pending:
      return {
        ...state,
        loading: true,
      };
    case _.update.success:
      return {
        ...state,
        loading: true,
        selectedSubscriber: { ...action.payload.updatedSubscriber },
        form: { ...action.payload.updatedSubscriber },
      };
    case _.update.fail:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };

    case _.createNewSubscriber.pending:
      return {
        ...state,
        loading: true,
      };
    case _.createNewSubscriber.success:
      return {
        ...state,
        loading: true,
        selectedSubscriber: { ...action.payload.data },
      };
    case _.createNewSubscriber.fail:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case _.updateFrom:
      return {
        ...state,
        form: { ...state.form, ...action.payload.newData },
      };

    default:
      return state;
  }
}
