import _ from "./actionType";

const initialState = {
    // subscribers: { totalCount: "", dataByPage: { "<page number>": [] } },

    selectedFilter: "",
    lendersIdsByFilter: {
        "<selectedFilter>": {
            totalCount: "",
            dataByPage: {
                "<page number>": [],
            },
            loadingByPage: { "<page number>": false },
            selectedPage: 1,
        },
    },
    searchLendersLoading: "",
    errorMessage: "",
    pageSize: 10,

    //array as object id is the primary key
    lendersList: {},
};

export default function lenders(state = initialState, action) {
    if (!action.payload) {
        return {...state };
    }
    const {
        pageSize,
        pageNumber,
        data,
        filter,
        totalCount,
        errorMessage,
        // selectedPage,
    } = action.payload;
    // console.log("action.type");
    // console.log(action.type);
    // console.log("action.payload");
    // console.log(action.payload);

    switch (action.type) {
        case _.searchLenders.pending:
            // pageNumber, filter, pageSize;

            if (state.lendersIdsByFilter[filter]) {
                return {
                    ...state,
                    searchLendersLoading: true,
                    lendersIdsByFilter: {
                        ...state.lendersIdsByFilter,
                        [filter]: {
                            ...state.lendersIdsByFilter[filter],
                            loadingByPage: {
                                [pageNumber]: true,
                            },
                        },
                    },
                };
            } else {
                return {
                    ...state,
                    searchLendersLoading: true,
                    lendersIdsByFilter: {
                        ...state.lendersIdsByFilter,
                        [filter]: {
                            ...state.lendersIdsByFilter[filter],
                            loadingByPage: {
                                [pageNumber]: true,
                            },
                            // totalCount: state.subscriberIdsByFilter[filter].totalCount,
                            totalCount: 0,
                            dataByPage: {},
                            selectPage: 1,
                        },
                    },
                };
            }

        case _.searchLenders.success:
            //pageNumber, filter, pageSize
            return {
                ...state,
                searchLendersLoading: false,
                lendersIdsByFilter: {
                    ...state.lendersIdsByFilter,
                    [filter]: {
                        ...state.lendersIdsByFilter[filter],
                        totalCount: totalCount,
                        dataByPage: {
                            ...state.lendersIdsByFilter[filter].dataByPage,
                            [pageNumber]: [...selectPKs(data)],
                        },
                        loadingByPage: {
                            [pageNumber]: false,
                        },
                    },
                },
                lendersList: {...state.lendersList, ...arrToObj(data) },
            };
        case _.searchLenders.fail:
            //pageNumber, filter, pageSize
            return {
                ...state,
                searchLendersLoading: false,
                errorMessage: errorMessage,
                lendersIdsByFilter: {
                    [filter]: {
                        totalCount: null,
                        dataByPage: {
                            ...state.lendersIdsByFilter[filter].dataByPage,
                            [pageNumber]: [],
                        },
                        loadingByPage: {
                            [pageNumber]: false,
                        },
                    },
                },
            };

        case _.selectPage:
            //pageNumber, filter, pageSize
            return {
                ...state,
                searchLendersLoading: false,
                lendersIdsByFilter: {
                    ...state.lendersIdsByFilter,
                    [filter]: {
                        ...state.lendersIdsByFilter[filter],
                        selectedPage: pageNumber,
                    },
                },
            };

        case "updateLender/update/success":
            return {
                ...state,
                lendersList: {
                    ...state.lendersList,
                    [action.payload.updatedLender.id]: {
                        ...action.payload.updatedLender,
                    },
                },
            };
        default:
            return state;
    }
}

const selectPKs = (arr) => {
    const primaryKey = "id";

    return arr.map((e) => e[primaryKey]);
};

const arrToObj = (arr) => {
    const primaryKey = "id";
    const arrAsObj = {};
    arr.forEach((e) => {
        arrAsObj[e[primaryKey]] = {...e };
    });

    return arrAsObj;
};