import { Container, Row, Button, Col } from 'react-bootstrap';
import { useState } from 'react';
import Lender from './Lender';
import ProductInfo from './ProductInfo';
import BundleFeats from './BundleFeat';
import FinancialInfo from './FinancialInfo';
import Specials from './Specials';

const FormScreen = () => {
  const [active, setActive] = useState('lender');
  return (
    <Container className="d-flex row justify-content-center mt-5">
      <Row>
        <Row>
          <div id="progress-bar-button" class="multisteps-form">
            <div class="row">
              <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
                <div class="multisteps-form__progress">
                  <a
                    class="btn multisteps-form__progress-btn js-active"
                    role="button"
                    title="User Info"
                    onClick={() => setActive('lender')}
                  >
                    Lender
                  </a>
                  <a
                    class="btn multisteps-form__progress-btn"
                    role="button"
                    title="User Info"
                    onClick={() => setActive('proinfo')}
                  >
                    Product Information
                  </a>
                  <a
                    class="btn multisteps-form__progress-btn"
                    role="button"
                    title="User Info"
                    onClick={() => setActive('bundleft')}
                  >
                    Bundle features
                  </a>
                  <a
                    class="btn multisteps-form__progress-btn"
                    role="button"
                    title="User Info"
                    onClick={() => setActive('fininfo')}
                  >
                    Financial Information
                  </a>
                  <a
                    class="btn multisteps-form__progress-btn"
                    role="button"
                    title="User Info"
                    onClick={() => setActive('spe')}
                  >
                    Specials
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Row>
      <Row>
        {active === 'lender' && <Lender />}
        {active === 'proinfo' && <ProductInfo />}
        {active === 'bundleft' && <BundleFeats />}
        {active === 'fininfo' && <FinancialInfo />}
        {active === 'spe' && <Specials />}
      </Row>
    </Container>
  );
};
export default FormScreen;
