import _ from './actionType';

const initialState = {
  loading: false,
  data: {},
  loadingByPage: {},
  errorMessage: null
};

export default function billing(state = initialState, action) {
  switch (action.type) {
    case _.getBillings.pending:
      return {
        ...state,
        loading: true,
        loadingByPage: {
          [action.payload.pageNumber]: true
        }
      };
    case _.getBillings.success:
      return {
        ...state,
        loading: false,
        loadingByPage: {
          ...state.loadingByPage,
          [action.payload.pageNumber]: false
        },
        data: {
          ...state.data,
          [action.payload.pageNumber]: [...action.payload.data]
        }
      };
    case _.getBillings.fail:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loadingByPage: {
          ...state.loadingByPage,
          [action.payload.pageNumber]: false
        }
      };
    default:
      return state;
  }
}
