import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
// import "../App.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// (companyName, billingAdd)

const BrokerAgency = () => {
  const [companyName, setCompanyName] = useState("");
  const [billingAdd, setBillingAdd] = useState("");

  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: "43rem", height: "26rem", fontSize: "1rem" }}
        className="shadow-lg rounded border-0"
      >
        <Card.Body style={{ color: "#8c8c8c" }} className="fw-bolder">
          <Row className="text-center m-2">
            <Col>
              <h4 style={{ fontSize: "1.8rem" }}>Broker Agency</h4>
            </Col>
          </Row>
          <Form className="">
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Company Name or ABN Search</Form.Label>
                  <Form.Control
                    className="rounded-3"
                    type="text"
                    placeholder="ABN"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Billing Address</Form.Label>
                  <Form.Control
                    className="rounded-3"
                    type="text"
                    placeholder="Sunset Blvd, 38"
                    value={billingAdd}
                    onChange={(e) => setBillingAdd(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Logo</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Choose File
                    </InputGroup.Text>
                    <FormControl
                      placeholder="No file chosen"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Row>
                <Col>
                  <Button>Prev</Button>
                  <Button>Next</Button>
                </Col>
                <Col>
                  <Link to="/subscriptions">
                    <Button>Quit</Button>
                  </Link>
                </Col>
              </Row>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BrokerAgency;
