import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import LoanTable from "./Forms/LoanTable";
import "../../../styles/App.css";

const LoanPackage = () => {
  const dispatch = useDispatch();

  return (
    <body id="page-top">
      <div id="wrapper">
        <Container className="d-flex flex-column" id="content-wrapper">
          <LoanTable />
          {/* <Footer /> */}
        </Container>
      </div>
    </body>
  );
};

export default LoanPackage;
