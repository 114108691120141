import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Multi-step-form.css';
import './styles/App.css';

import React from 'react';

import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Subscriptions from './components/pages/Subscriptions';
import Billing from './components/pages/Billing';
import LoanPackage from './components/pages/LoanPackage';

import ManageAccount from './components/pages/Subscriptions/topButtons/ManageAccount';
import RecentClosed from './components/pages/Subscriptions/topButtons/RecentClosed';

import LoanForm from './components/pages/LoanPackage/Forms/FormsScreen';
// import ManageAccount from "./components/pages/Subscriptions/topForms/ManageAccount";

import Sidebar from './components/layout/Sidebar';

import { Container } from 'react-bootstrap';
import { BsArrowUpSquare } from 'react-icons/bs';

import Topbar from './components/layout/Topbar';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route
} from 'react-router-dom';
import { useSelector } from 'react-redux';

function App() {
  // const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <div className="#">
        {isAuthenticated ? (
          <div className="d-flex h-100">
            <div className="sidb-respons2">
              <Sidebar />
            </div>
            <div className="w-100 h-100">
              <Topbar />
              <div>
                <Container>
                  <Switch>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />
                    <Route
                      path="/subscriptions/manageacount"
                      element={<ManageAccount />}
                    />
                    <Route path="/billing" element={<Billing />} />
                    <Route path="/loanpackage" element={<LoanPackage />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/loanform" element={<LoanForm />} />
                    <Route path="/manageAccount" element={<ManageAccount />} />
                  </Switch>
                  <a className="to-top" href="#">
                    <BsArrowUpSquare
                      style={{ height: '40px', width: '50px' }}
                    />
                  </a>
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <Login />
        )}
      </div>
    </Router>
  );
}

export default App;
