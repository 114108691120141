const getSubscriber = {
  pending: "updateSubscriber/getSubscriber/pending",
  success: "updateSubscriber/getSubscriber/success",
  fail: "updateSubscriber/getSubscriber/fail",
};
const update = {
  pending: "updateSubscriber/update/pending",
  success: "updateSubscriber/update/success",
  fail: "updateSubscriber/update/fail",
};
const createNewSubscriber = {
  pending: "updateSubscriber/createNewSubscriber/pending",
  success: "updateSubscriber/createNewSubscriber/success",
  fail: "updateSubscriber/createNewSubscriber/fail",
};

const updateFrom = "updateSubscriber/updateFrom";
const selectSubscriber = "updateSubscriber/selectSubscriber";
const removeSelectedSubscriber = "updateSubscriber/removeSelectedSubscriber";

export default {
  getSubscriber,
  update,
  createNewSubscriber,
  selectSubscriber,
  updateFrom,
  removeSelectedSubscriber,
};
