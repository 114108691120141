import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Card,
  Button
} from 'react-bootstrap';
import Loader from '../../common/Loader';
import dog from './image3.jpeg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, clearAllError } from '../../../store/auth/actions';

const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { loading } = useSelector((state) => state.auth);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <Container className="text-secondary d-flex justify-content-center">
      <Card
        id="card"
        className="rounded shadow-lg o-hidden border-0 my-5 col-sm-6 col-md-8 col-lg-10 col-xl-10"
      >
        <Card.Body className="p-0">
          <Row className="">
            <Col className="p-0 col-lg-6 d-none d-lg-flex img-responsive">
              <Image
                style={{ height: '33rem', width: '30rem', objectFit: 'cover' }}
                className="img"
                src={dog}
                alt="dog"
                fluid
              />
            </Col>
            <Col className="p-5">
              <div className="text-center">
                <h4 className="text-dark mb-4">Welcome Back!</h4>
              </div>
              <Form className="user" onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="exampleInputEmail">
                  {/* <Form.Label>Email address</Form.Label> */}
                  <Form.Control
                    className="rounded-pill p-3"
                    type="email"
                    placeholder="Enter Email Address..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {/* <Form.Text className="text-muted"></Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleInputPassword">
                  {/* <Form.Label>Password</Form.Label> */}
                  <Form.Control
                    className=" rounded-pill p-3"
                    type="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>

                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    type="submit"
                    className="bg-gradient-primary btn btn-primary d-block btn-user w-100 rounded-pill p-3"
                  >
                    Login
                  </Button>
                )}
                <hr />

                <div className="text-center">
                  <a
                    className="small text-secondary text-decoration-none"
                    href="forgot-password.html"
                  >
                    Forgot Password?
                  </a>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
