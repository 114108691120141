import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import subscriptionsReducer from "./subscriptions/reducer";
import billingReducer from "./billing/reducer";

// import getSubscribers from "./getSubscribers/reducer";
import updateSubscribers from "./updateSubscriber/reducer";
import searchLoanPackage from "./searchLoanPackage/reducer";
import manageLoanPackage from "./manageLoanPackage/reducer";

const globalReducer = combineReducers({
    auth: authReducer,
    subscriptions: subscriptionsReducer,
    billing: billingReducer,
    loanPackage: searchLoanPackage,
    manageLoan: manageLoanPackage,
    // getSubscribers,
    updateSubscribers,
});

export default globalReducer;