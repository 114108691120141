import { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  FormControl
} from 'react-bootstrap';
import { AiFillCalendar } from 'react-icons/ai';
// import "../App.css";

const ProductInfo = () => {
  // productName, bundleName
  const [productName, setProductName] = useState('');
  const [bundleName, setBundleName] = useState('');

  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: '43rem', height: '20rem', fontSize: '1rem' }}
        className="shadow-lg rounded border-0"
      >
        <Card.Body style={{ color: '#808080' }} className="fw-bolder">
          <Row>
            <Col>
              <h4 style={{ fontSize: '1.8rem' }} className="text-center">
                Basic Details
              </h4>
            </Col>
          </Row>

          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Product Name</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="ANZ Variable"
                      value={productName}
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Bundle Name</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="ANZ Wonderful Home Loan 30"
                      value={bundleName}
                      onChange={(e) => setBundleName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Row>
                <Col>
                  <Button>Prev</Button>
                  <Button>Next</Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProductInfo;
