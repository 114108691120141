import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MainBroker from "./MainBrokerForm";
import BrokerAgency from "./BrokerAgency";
import SubscriptionPlan from "./SubscriptionPlan";
import Otp from "./OptScreen";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../store/actions";
const FormScreen = () => {
  const [active, setActive, setSelect, isSelected] = useState("main");

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.updateSubscribers.getSubscriber());
  }, []);

  return (
    <Container className="d-flex row justify-content-center mt-5">
      <Row>
        <Row>
          <div id="progress-bar-button" className="multisteps-form">
            <Row className="row">
              <div className="col-12 col-lg-8 ml-auto mr-auto mb-4">
                <div className="multisteps-form__progress">
                  <Link
                    className="btn multisteps-form__progress-btn js-active"
                    //js-active
                    eventKey="link-1"
                    role="button"
                    title="User Info"
                    to="#"
                    onClick={() => setActive("main")}
                  >
                    Account Information
                  </Link>
                  <Link
                    className={`btn multisteps-form__progress-btn`}
                    eventKey="link-2"
                    to="#"
                    role="button"
                    title="User Info"
                    onClick={
                      () => setActive("broker")
                      // &&
                      // setSelect(!isSelected ? isSelected : !isSelected)
                      // setSelect(Link !== 'js-active' ? 'js-active' : '')
                      // setSelect(
                      //   Link !== 'js-active' ? Link === 'js-active' : ''
                      // )
                    }
                  >
                    Agency Details
                  </Link>
                  <Link
                    className="btn multisteps-form__progress-btn "
                    to="#"
                    role="button"
                    title="User Info"
                    onClick={() => setActive("subplan")}
                  >
                    Subscription
                  </Link>
                  <Link
                    className="btn multisteps-form__progress-btn"
                    to="#"
                    role="button"
                    title="User Info"
                    onClick={() => setActive("otp")}
                  >
                    Verify OTP
                  </Link>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Row>
      <Row>
        {active === "main" && <MainBroker />}

        {active === "broker" && <BrokerAgency />}
        {active === "subplan" && <SubscriptionPlan />}
        {active === "otp" && <Otp />}
      </Row>
    </Container>
  );
};
export default FormScreen;
