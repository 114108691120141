import _ from "./actionType";

const initialState = {
    selectedLender: {},
    getLenderLoading: false,

    form: {},

    updateLenderLoading: false,

    createNew: true,
    errorMessage: null,
};

export default function updateLenders(state = initialState, action) {
    console.log(action);
    if (!action.payload) return state;
    const { selectedLenderData } = action.payload;
    switch (action.type) {
        // case _.selectSubscriber:
        //   return {
        //     ...state,
        //     createNew: false,
        //     selectedSubscriber: selectedSubscriberData,
        //   };

        case _.getLender.pending:
            return {
                ...state,
                getLenderLoading: true,
            };
        case _.getLender.success:
            return {
                ...state,
                getLenderLoading: true,
                selectedLender: {...action.payload.data },
                form: {...action.payload.data },
            };
        case _.getLender.fail:
            return {
                ...state,
                getLenderLoading: false,
                errorMessage: action.payload.errorMessage,
            };

        case _.update.pending:
            return {
                ...state,
                loading: true,
            };
        case _.update.success:
            return {
                ...state,
                loading: true,
                selectLender: {...action.payload.updatedLender },
                form: {...action.payload.updatedLender },
            };
        case _.update.fail:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage,
            };

        case _.createNewLender.pending:
            return {
                ...state,
                loading: true,
            };
        case _.createNewLender.success:
            return {
                ...state,
                loading: true,
                selectedLender: {...action.payload.data },
            };
        case _.createNewLender.fail:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.errorMessage,
            };
        case _.updateForm:
            return {
                ...state,
                form: {...state.form, ...action.payload.newData },
            };
        default:
            return state;
    }
}