import { Table, Row, Col, Button, Card, Container, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Footer from '../../layout/Footer';

import * as actions from '../../../store/actions';

const Accounts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loadingByPage } = useSelector((store) => store.subscriptions);

  const dispatch = useDispatch();

  const totalDataCount = 100;
  const pageSize = 3;
  const pageNumber = Math.floor(totalDataCount / pageSize);

  const [selectCheck, setSelectCheck] = useState(false);

  const checkboxHandler = () => {
    setSelectCheck(!selectCheck);
  };

  useEffect(() => {
    if (!data[currentPage]) {
      console.log(
        'no data in the frontend. needed to be fatched from backend.'
      );
      // dispatch(actions.subscriptions.getSubscribers(pageSize, currentPage));
    }
    console.log('current page changed');
  }, [currentPage]);

  return (
    <div className="tab-content">
      <div className="tab-pane active" role="tabpanel" id="tab-1">
        <Row>
          <Col>
            <Card className="card shadow">
              <Card className="card-header py-3">
                <div className="hstack gap-2">
                  <button className="btn" type="button">
                    Brokers
                  </button>
                  <button className="btn" type="button">
                    Conveyancers
                  </button>
                  <button className="btn" type="button">
                    Buyers Agents
                  </button>
                  <button className="btn" type="button">
                    Accountants
                  </button>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="input-group">
                      <input
                        class="bg-light form-control border-0 small"
                        type="text"
                        placeholder="From date ..."
                      />
                      <button class="btn btn-primary py-0" type="button">
                        <i class="fas fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="input-group">
                      <input
                        class="bg-light form-control border-0 small"
                        type="text"
                        placeholder="To date ..."
                      />
                      <button class="btn btn-primary py-0" type="button">
                        <i class="far fa-calendar-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Card>
              <Card.Body>
                <div
                  className="table-responsive table mt-2"
                  id="dataTable"
                  role="grid"
                  aria-describedby="dataTable_info"
                >
                  <Table className="table-sm text-secondary">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Post code</th>
                        <th>Phone</th>
                        <th>Agency</th>
                        <th>Trial end date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {' '}
                      {data[currentPage] &&
                        data[currentPage].map((each, i) => {
                          const trialEndDate = new Date(each.trialEndDate);
                          return (
                            <tr key={i}>
                              <td>
                                <input
                                  type="checkbox"
                                  id="selectCheck"
                                  onChange={checkboxHandler}
                                />
                              </td>
                              <td>
                                {each.fistName} {each.lastName}
                              </td>
                              <td>{each.postCode}</td>
                              <td>{each.phone}</td>
                              <td>{each.agency}</td>
                              <td>{`${trialEndDate.getFullYear()}/${
                                trialEndDate.getMonth() + 1
                              }/${trialEndDate.getDate()}`}</td>

                              <td>{each.status}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                <Row>
                  <Col className="col-md-6 align-self-center">
                    <p
                      id="dataTable_info"
                      className="dataTables_info"
                      role="status"
                      aria-live="polite"
                    >
                      {/* Showing{" "}
                      {currentPage > 1 ? (currentPage - 1) * pageSize : 1} to{" "}
                      {currentPage * pageSize} of &#8734; */}
                      Showing{' '}
                      {currentPage < pageNumber
                        ? (currentPage - 1) * pageSize
                        : 96}{' '}
                      to{' '}
                      {currentPage < totalDataCount
                        ? currentPage * pageSize
                        : 99}{' '}
                      of {totalDataCount}
                    </p>
                  </Col>
                  <Col className="col-md-6">
                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                      <Nav className="pagination">
                        <Nav.Item
                          className={`page-item ${
                            currentPage <= 1 && 'disabled'
                          }`}
                          onClick={(e) => {
                            if (currentPage > 1) {
                              setCurrentPage(currentPage - 1);
                            }
                            e.preventDefault();
                          }}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">«</span>
                          </Link>
                        </Nav.Item>
                        {currentPage < 1 ? (
                          <Nav.Item onClick={''} className="page-item active">
                            <Link className="page-link" to="#">
                              {currentPage < pageNumber
                                ? currentPage
                                : pageNumber}
                            </Link>
                          </Nav.Item>
                        ) : (
                          <>
                            <Nav.Item className="page-item">
                              <Link className="page-link" to="#">
                                {currentPage < pageNumber
                                  ? currentPage - 1
                                  : pageNumber - 1}
                              </Link>
                            </Nav.Item>
                            <Nav.Item
                              className={`page-item ${
                                currentPage !== 'active' ? 'active' : ''
                              }`}
                            >
                              <Link className="page-link" to="#">
                                {currentPage < pageNumber
                                  ? currentPage
                                  : pageNumber}
                              </Link>
                            </Nav.Item>

                            <Nav.Item className="page-item">
                              <Link className="page-link" to="#">
                                {/* {currentPage + 1} */}
                                {currentPage < pageNumber
                                  ? currentPage + 1
                                  : pageNumber + 1}
                              </Link>
                            </Nav.Item>
                          </>
                        )}
                        {/* {currentPage === 1 ? (
                          <>
                            <Nav.Item onClick={""} className="page-item active">
                              <Link className="page-link" to="#">
                                1
                              </Link>
                            </Nav.Item>
                            <Nav.Item onClick={""} className="page-item">
                              <Link className="page-link" to="#">
                                2
                              </Link>
                            </Nav.Item>
                            <Nav.Item onClick={""} className="page-item">
                              <Link className="page-link" to="#">
                                3
                              </Link>
                            </Nav.Item>
                          </>
                        ) : (
                          <>
                            <Nav.Item className="page-item">
                              <Link className="page-link" to="#">
                                {currentPage - 1}
                              </Link>
                            </Nav.Item>
                            <Nav.Item
                              className={`page-item ${
                                currentPage !== "active" ? "active" : ""
                              }`}
                            >
                              <Link className="page-link" to="#">
                                {currentPage}
                              </Link>
                            </Nav.Item>
                            <Nav.Item className="page-item">
                              <Link className="page-link" to="#">
                                {currentPage + 1}
                              </Link>
                            </Nav.Item>
                          </>
                        )} */}
                        <Nav.Item
                          onClick={(e) => {
                            setCurrentPage(currentPage + 1);
                            e.preventDefault();
                          }}
                          className="page-item"
                        >
                          <Link className="page-link" to="#" aria-label="Next">
                            <span aria-hidden="true">»</span>
                          </Link>
                        </Nav.Item>
                      </Nav>
                    </nav>
                  </Col>
                </Row>{' '}
                <Button disabled={!selectCheck} className="p-0">
                  <Link
                    className="btn btn-primary btn-sm d-none d-sm-inline-block"
                    role="button"
                    to="/manageAccount"
                  >
                    <i className="fas fa-battery-half fa-sm text-white-50"></i>
                    &nbsp;Next{' '}
                  </Link>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Accounts;
