import React, { useState } from 'react';
import RecentClosed from './topButtons/RecentClosed';
import ManageAccount from './topButtons/ManageAccount';
import Accounts from './Accounts';
import Footer from '../../layout/footer';
import '../../../styles/App.css';

import { Nav, Container } from 'react-bootstrap';

const Subscriptions = () => {
  const [active, setActive] = useState('accounts');
  return (
    <body id="page-top" className="d-flex row justify-content-center">
      <div id="wrapper" className="">
        <div
          className="d-flex flex-column justify-content-center"
          id="content-wrapper"
        >
          <div id="content">
            <Container className="container-fluid">
              <div>
                <Nav className="nav nav-tabs" role="tablist">
                  <Nav.Item className="nav-item" role="presentation">
                    <Nav.Link
                      className={`nav-link ${
                        Nav.Link !== 'active' ? '' : 'active'
                      }`}
                      role="tab"
                      data-bs-toggle="tab"
                      to="#tab-1"
                      onClick={() => setActive('accounts')}
                    >
                      Accounts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" role="presentation">
                    <Nav.Link
                      className={`nav-link ${
                        Nav.Link !== 'active' ? '' : 'active'
                      }`}
                      role="tab"
                      data-bs-toggle="tab"
                      to="#tab-2"
                      onClick={() => setActive('manage')}
                    >
                      Manage Account
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" role="presentation">
                    <Nav.Link
                      className={`nav-link ${
                        Nav.Link !== 'active' ? '' : 'active'
                      }`}
                      role="tab"
                      data-bs-toggle="tab"
                      to="#tab-3"
                      onClick={() => setActive('recent')}
                    >
                      Recently Closed
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {/* <Accounts /> */}
                {/* <ManageAccount /> */}
                {active === 'accounts' && <Accounts />}
                {active === 'manage' && <ManageAccount />}
                {active === 'recent' && <RecentClosed />}
              </div>
              <Footer />
            </Container>
          </div>
        </div>
      </div>
    </body>
  );
};
export default Subscriptions;
