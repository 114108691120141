import '../../App.css';
import React from 'react';
import image from './avatar1.jpeg';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

const TopBar = () => {
  return (
    <div id="wrapper w-100 topdb-respons">
      <nav className="navbar navbar-light navbar-expand bg-white shadow mb-4 p-3 topbar static-top">
        <div className="container-fluid">
          <button
            className="btn btn-link d-md-none rounded-circle me-3"
            id="sidebarToggleTop"
            type="button"
          >
            <i className="fas fa-bars"></i>
          </button>
          <h3 className="mb-0 ">Admin Portal</h3>
          <ul className="navbar-nav flex-nowrap ms-auto">
            <li className="nav-item dropdown d-sm-none no-arrow">
              <a
                className="nav-link align-items-center"
                style={{ color: '#dbdbdb' }}
                aria-expanded="false"
                aria-haspopup="true"
                data-toggle="dropdown"
                href="#"
              >
                <i className="fas fa-search"></i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end p-3 animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <form className="me-auto navbar-search w-100">
                  <div className="input-group">
                    <input
                      className="bg-light form-control border-0 small"
                      type="text"
                      placeholder="Search for ..."
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary py-0" type="button">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>
            <div className="vr d-sm-block"></div>
            <li className="">
              <div className="">
                <a
                  className="d-flex align-items-center"
                  aria-expanded="false"
                  data-bs-toggle="dropdown"
                  href="#"
                >
                  <span className="d-none d-lg-inline me-2 text-gray-600 small text-secondary">
                    Valerie Luna
                  </span>
                  <img
                    className="border rounded-circle img-profile"
                    style={{ height: '32px' }}
                    src={image}
                  />
                  <div>
                    <NavDropdown title="" id="nav-dropdown">
                      <NavDropdown.Item eventKey="4.1">Logout</NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default TopBar;
