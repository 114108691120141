import '../../../App.css';
import {
  Card,
  Container,
  Row,
  Col,
  Nav,
  NavDropdown,
  Navbar,
  ProgressBar,
  Button
} from 'react-bootstrap';
import { BsCircleFill } from 'react-icons/bs';
import Chart from 'react-apexcharts';
import Footer from '../../layout/footer';

export default function () {
  return (
    <div>
      <Container className="m-0">
        <Row className="mb-4">
          <Col id="chart" className="mb-4">
            <Card id="inner-chart" className="shadow p-0">
              <Card.Body className="p-0">
                <Nav className="justify-content-between align-items-center border bg-light">
                  <Nav.Item className="text-primary fw-bold m-0 ">
                    <Nav.Link href="/home">User Accounts</Nav.Link>
                  </Nav.Item>

                  <Navbar.Toggle />

                  <NavDropdown className="no-arrow " title="" id="nav-dropdown">
                    <NavDropdown.Header>Dropdown Header</NavDropdown.Header>
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.3">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                <Chart
                  type="area"
                  series={[
                    {
                      name: 'Loan',
                      data: [0, 10000, 5000, 15000, 10000, 20000, 15000, 25000]
                    }
                  ]}
                  options={{
                    chart: {
                      toolbar: {
                        show: false
                      }
                    },
                    dataLabels: {
                      enabled: false
                    },
                    markers: {
                      size: 0
                    },
                    stroke: {
                      curve: 'smooth'
                    },
                    colors: ['#304cff'],

                    xaxis: {
                      categories: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug'
                      ]
                    }
                  }}
                ></Chart>
              </Card.Body>
            </Card>
          </Col>

          <Col className="">
            <Card id="circle" className="shadow mb-4">
              <Card.Body className="p-0">
                <Nav className="justify-content-between align-items-center border bg-light">
                  <Nav.Item className="text-primary fw-bold m-0 ">
                    <Nav.Link href="/home">Clients</Nav.Link>
                  </Nav.Item>

                  <Navbar.Toggle />

                  <NavDropdown className="no-arrow " title="" id="nav-dropdown">
                    <NavDropdown.Header>Dropdown Header</NavDropdown.Header>
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.3">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                <Chart
                  type="donut"
                  series={[15, 50, 30]}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: false
                    },
                    labels: ['Referral', 'Direct', 'Social'],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '48px'
                        }
                      }
                    },
                    tooltip: {
                      y: {
                        formatter: (val) => {
                          return `${val}`;
                        }
                      }
                    },
                    colors: ['#42daf5', '#426cf5', '#00d48a']
                  }}
                ></Chart>
                <Card.Text className="d-flex mt-5 align-items-center fw-light p-2">
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#42daf5' }} />
                    <span> Brokers</span>
                  </div>
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#426cf5' }} />
                    <span> Conveyancers</span>
                  </div>
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#00d48a' }} />
                    <span> Accountants</span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="mb-4">
            <Card id="chart-2" className="shadow">
              <Card.Body className="p-0">
                <Nav className="justify-content-between align-items-center border bg-light">
                  <Nav.Item className="text-primary fw-bold m-0 ">
                    <Nav.Link href="/home">User</Nav.Link>
                  </Nav.Item>

                  <Navbar.Toggle />

                  <NavDropdown className="no-arrow " title="" id="nav-dropdown">
                    <NavDropdown.Header>Dropdown Header</NavDropdown.Header>
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.3">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>

                <div className="fw-bold m-4 text-secondary">
                  <Col>
                    <h4 className="small fw-bold">
                      Linked In<span className="float-end">20%</span>
                    </h4>
                    <ProgressBar className="mb-4" variant="danger" now={20} />
                  </Col>

                  <Col>
                    <h4 className="small fw-bold">
                      Facebook<span className="float-end">40%</span>
                    </h4>
                    <ProgressBar className="mb-4" variant="warning" now={40} />
                  </Col>

                  <Col>
                    <h4 className="small fw-bold">
                      Velocity<span className="float-end">60%</span>
                    </h4>
                    <ProgressBar className="mb-4" now={60} />
                  </Col>

                  <Col>
                    <h4 className="small fw-bold">
                      Cognito<span className="float-end">80%</span>
                    </h4>
                    <ProgressBar className="mb-4" variant="info" now={80} />
                  </Col>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col className="p-0">
            <Card id="circle-2" className="shadow mb-4">
              <Card.Body className="p-0">
                <Nav className="justify-content-between align-items-center border bg-light">
                  <Nav.Item className="text-primary fw-bold m-0 ">
                    <Nav.Link href="/home">Loan Activity</Nav.Link>
                  </Nav.Item>

                  <Navbar.Toggle />

                  <NavDropdown className="no-arrow " title="" id="nav-dropdown">
                    <NavDropdown.Header>Dropdown Header</NavDropdown.Header>
                    <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                    <NavDropdown.Item eventKey="4.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.3">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                {/* <Card.Title>Clients</Card.Title> */}
                <Chart
                  type="donut"
                  series={[15, 50, 30]}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    legend: {
                      show: false
                    },
                    labels: ['Referral', 'Direct', 'Social'],
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '48px'
                        }
                      }
                    },
                    tooltip: {
                      y: {
                        formatter: (val) => {
                          return `${val}`;
                        }
                      }
                    },
                    colors: ['#42daf5', '#426cf5', '#00d48a']
                  }}
                ></Chart>
                <Card.Text className="d-flex align-items-center fw-light">
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#42daf5' }} />
                    <span> Brokers</span>
                  </div>
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#426cf5' }} />
                    <span> Conveyancers</span>
                  </div>
                  <div className="m-1">
                    <BsCircleFill style={{ color: '#00d48a' }} />
                    <span> Accountants</span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </Container>
    </div>
  );
}
