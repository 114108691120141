const getLender = {
    pending: "updateLender/getLender/pending",
    success: "updateLender/getLender/success",
    fail: "updateLender/getLender/fail",
};

const update = {
    pending: "updateLender/update/pending",
    success: "updateLender/update/success",
    success: "updateLender/update/success",
};

const createNewLender = {
    pending: "updateLender/createLender/pending",
    success: "updateLender/createLender/success",
    fail: "updateLender/createLender/fail",
};

const updateForm = "updateLender/updateForm";
const selectLender = "updateLender/selectLender";
const removeSelectedLender = "updateLender/removeSelectedLender";

export default {
    getLender,
    update,
    createNewLender,
    updateForm,
    selectLender,
    removeSelectedLender,
};