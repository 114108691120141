import React from 'react';
import '../../App.css';
import { Link, useLocation } from 'react-router-dom';
import { HiChevronLeft } from 'react-icons/hi';
import LoanAnalysis from './LoanAnalysis.png';
import { useState } from 'react';

// const handleSideBarBtn = (e) => {
//   e.preventDefault();
// };

const Sidebar = () => {
  const [wdth, setWdth] = useState('200px');
  const [text, setText] = useState('');

  const location = useLocation();
  return (
    <div style={{ width: wdth }} className="sidb-respons sidb-respons-md">
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.12.0/css/all.css"
      />
      <link
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <nav className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
        <div className="container-fluid d-flex flex-column p-0 h100">
          <Link
            className=" navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0"
            to="#"
          >
            <img
              src={LoanAnalysis}
              alt="loan"
              style={{ padding: '15px 0' }}
              width="100"
            />
          </Link>
          <hr className="sidebar-divider my-0" />
          <ul className="navbar-nav text-light sml1" id="accordionSidebar">
            <li className="nav-item pagess">
              <Link
                className={`nav-link ${
                  location.pathname === '/' ||
                  location.pathname === '/dashboard'
                    ? 'active'
                    : ''
                }`}
                to="dashboard"
              >
                <span>
                  <i className="fas fa-tachometer-alt fa-lg padding dashboard2" />
                </span>
                <span> Dashboard</span>
              </Link>
            </li>
            <li className="nav-item pagess">
              <Link
                className={`nav-link ${
                  location.pathname === '/subscriptions' ? 'active' : ''
                }`}
                to="subscriptions"
              >
                <span>
                  <i className="fas fa-table fa-lg padding" />
                </span>
                <span> Subscriptions</span>
              </Link>
            </li>
            <li className="nav-item pagess billing">
              <Link
                className={`nav-link ${
                  location.pathname === '/billing' ? 'active' : ''
                }`}
                to="billing"
              >
                <span>
                  <i className="fas fa-file-invoice-dollar padding fa-lg  billing2" />
                </span>

                <span> Billing</span>
              </Link>
            </li>
            <li className="nav-item pagess">
              <Link
                className={`nav-link ${
                  location.pathname === '/loanpackage' ? 'active' : ''
                }`}
                to="loanpackage"
              >
                <span>
                  <i className="fas fa-gem padding" />
                </span>
                <span> Loan Package</span>
              </Link>
            </li>
          </ul>
          <div className="text-center d-none d-md-inline bg-re justify-content-center">
            <button
              className="btn rounded-circle border-0"
              id="sidebarToggle"
              type="button"
              onClick={() => {
                wdth === '200px' ? setWdth('95px') : setWdth('200px');
              }}
            >
              <HiChevronLeft
                className="icon"
                style={{
                  color: '#dedede',
                  marginBottom: '3px',
                  marginLeft: '-3px'
                }}
                size="20px"
              />
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
