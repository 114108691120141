import React from 'react';
import { Link, Row, Col } from 'react-router-dom';
import FormScreen from '../Forms/FormsScreen';

const ManageAccount = () => {
  return (
    <body id="page-top" className="d-flex row justify-content-center">
      <div id="wrapper" className="">
        <FormScreen />
      </div>
    </body>
  );
};

export default ManageAccount;
