export const searchSubscribers = {
  pending: "subscriptions/searchSubscriptions/pending",
  success: "subscriptions/searchSubscriptions/success",
  fail: "subscriptions/searchSubscriptions/fail",
};

export const selectSubscriber = "subscriptions/selectSubscriber";

export const selectPage = "subscriptions/selectPage";

export const requestUpdateSubscriber = {
  pending: "subscriptions/requestUpdateSubscriber/pending",
  success: "subscriptions/requestUpdateSubscriber/success",
  fail: "subscriptions/requestUpdateSubscriber/fail",
};

export const submitOTPOnUpdateSubscriber = {
  pending: "subscriptions/submitOTPOnUpdateSubscriber/pending",
  success: "subscriptions/submitOTPOnUpdateSubscriber/success",
  fail: "subscriptions/submitOTPOnUpdateSubscriber/fail",
};

export default {
  searchSubscribers,
  submitOTPOnUpdateSubscriber,
  requestUpdateSubscriber,
  selectSubscriber,
  selectPage,
};
