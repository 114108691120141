import React from 'react';

const footer = () => {
  return (
    <div>
      <div>
        <footer
          style={{ marginTop: '40px' }}
          className="bg-white sticky-footer"
        >
          <div className="container my-auto">
            <div className="text-center my-auto copyright">
              <span>Copyright © Loan Analysis Solutions 2022</span>
            </div>
          </div>
        </footer>
      </div>
      <a class="border rounded d-inline scroll-to-top" href="#page-top">
        <i class="fas fa-angle-up"></i>
      </a>
    </div>
  );
};

export default footer;
