import types from "./actionType";

import axios from "axios";

export const searchSubscribers =
  (pageNumber = 1, filter, pageSize) =>
  async (dispatch) => {
    dispatch({
      type: types.searchSubscribers.pending,
      payload: { pageSize, pageNumber, filter },
    });

    const res = await axios.get("http://localhost:5000/subscribers", {
      params: {
        pageSize: pageSize,
        totalDataCount: 100,
        filter,
      },
      validateStatus: false,
    });
    console.log(res);
    if (res.status !== 200) {
      dispatch({
        type: types.searchSubscribers.fail,
        payload: { errorMessage: "failled to load data", pageNumber, pageSize },
      });
    }

    dispatch({
      type: types.searchSubscribers.success,
      payload: {
        pageSize,
        pageNumber,
        data: res.data.data,
        filter,
        totalCount: res.data.totalCount,
      },
    });
  };

export const selectPage = (filter, pageNumber) => (dispatch) => {
  // console.log({ filter, pageNumber });
  dispatch({ type: types.selectPage, payload: { filter, pageNumber } });
};

export default {
  searchSubscribers,
};
