import React from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
// import { accountsSubscription } from '../../../../store/subscriptions/accounts/actions';
// import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "./../../../../store/actions";

const MainBroker = () => {
  // const [fullName, setFullName] = useState("");
  const [accountID, setAccountID] = useState(0);
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState(0);

  const form = useSelector((state) => state.updateSubscribers.form);

  const dispatch = useDispatch();

  const [fullName, setFullName] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [phone, setPhone] = React.useState(null);

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (!form) return;
    setFullName(form.firstName);
    setUsername(form.username);
    setPhone(form.phone);
  }, [form]);

  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: "43rem", height: "26rem", fontSize: "1rem" }}
        className="shadow-lg rounded border-0"
      >
        <Card.Body style={{ color: "#808080" }} className="fw-bolder">
          <Row className="text-center m-2">
            <Col>
              <h4 style={{ fontSize: "1.8rem" }}>Main Broker</h4>
            </Col>
          </Row>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    value={fullName}
                    type="text"
                    onChange={(e) => setFullName(e.target.value)}
                    className="rounded-3"
                    // disabled
                    placeholder="John"
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Account ID</Form.Label>
                  <Form.Control
                    value={accountID}
                    // type="number"
                    onChange={(e) => setAccountID(e.target.value)}
                    className="rounded-3"
                    // disabled
                    type="text"
                    placeholder="J983j-d9e832d-6eg2b"
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="rounded-3"
                    // disabled
                    type="text"
                    placeholder="user.name"
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    // type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="rounded-3"
                    // disabled
                    type="text"
                    placeholder="user@example.com"
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    // type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="rounded-3"
                    // disabled
                    type="text"
                    placeholder="041111111"
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: "45px" }}>
              <Col>
                <Button
                  onClick={() => {
                    dispatch(
                      actions.updateSubscribers.updateFrom({
                        username,
                        firstName: fullName,
                        phone,
                      })
                    );
                  }}
                >
                  Next
                </Button>
              </Col>
              <Col>
                <Link to="/subscriptions">
                  <Button>Quit</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MainBroker;
