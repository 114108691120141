import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Opt = () => {
  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: "43rem", height: "14rem", fontSize: "1rem" }}
        className="shadow-lg rounded border-0"
      >
        <Card.Body style={{ color: "#808080" }} className="fw-bolder">
          <Row>
            <Col>
              <h4 style={{ fontSize: "1.8rem" }} className="text-center">
                Subscription Plan
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Group>
                  <Form.Label>One Time Passcode</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      style={{ border: "0" }}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      placeholder="Plain Text Value"
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Row>
              <Col>
                <Button>Submit</Button>
              </Col>
              <Col>
                <Link to="/subscriptions">
                  <Button>Quit</Button>
                </Link>
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Opt;
