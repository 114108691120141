import React, { useState } from 'react';
import Accounts from './Accounts';
import PaymentHistory from './PaymentHistory';
import '../../../styles/App.css';

import Footer from '../../layout/footer';

import { Nav, Container } from 'react-bootstrap';

const Billing = () => {
  const [active, setActive] = useState('accounts');
  return (
    <body id="page-top" className="d-flex row justify-content-center">
      <div id="wrapper">
        <div
          className="d-flex flex-column justify-content-center"
          id="content-wrapper"
        >
          <div id="content">
            <Container className="container-fluid">
              <div>
                <Nav className="nav nav-tabs" role="tablist">
                  <Nav.Item className="nav-item" role="presentation">
                    <Nav.Link
                      className={`nav-link  ${
                        Nav.Link !== 'active' ? '' : 'active'
                      }`}
                      eventKey="link-1"
                      role="tab"
                      data-bs-toggle="tab"
                      to="#tab-1"
                      onClick={() => setActive('accounts')}
                    >
                      Accounts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item" role="presentation">
                    <Nav.Link
                      className={`nav-link ${
                        Nav.Link !== 'active' ? '' : 'active'
                      }`}
                      eventKey="link-2"
                      role="tab"
                      data-bs-toggle="tab"
                      to="#tab-2"
                      onClick={() => setActive('manage')}
                    >
                      Payment History
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {active === 'accounts' && <Accounts />}
                {active === 'manage' && <PaymentHistory />}
              </div>
              <Footer />
            </Container>
          </div>
        </div>
      </div>
    </body>
  );
};
export default Billing;
