import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Form,
  Card,
  InputGroup,
  FormControl,
  Button,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "../App.css";

const Lender = () => {
  const [shortName, setShortName] = useState("");
  const [lenderName, setLenderName] = useState("");
  const [newLender, setNewLender] = useState("");

  return (
    <Container className="d-flex justify-content-center row mt-2">
      <Card
        style={{ width: "43rem", height: "35rem", fontSize: "1rem" }}
        className="shadow-lg rounded border-0"
      >
        <Row className="text-center m-2">
          <Col>
            <h4
              style={{
                fontSize: "1.8rem",
                color: "#808080",
              }}
            >
              Loan Provider
            </h4>
          </Col>
        </Row>
        {/* (shortName, lenderName, newLender)  */}
        <Card.Body style={{ color: "#000000" }} className="fw-bolder">
          <Row className="border m-2 rounded-3 ">
            <Nav className=" py-4 px-3 pt-2">
              <Nav.Item>
                {/* <InputGroup> */}
                <Dropdown>
                  {/* <FormControl /> */}
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Select lender
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* </InputGroup> */}
              </Nav.Item>
            </Nav>
            <hr style={{ color: "#808080" }} />
            <Form>
              <Row>
                <Form.Group className="my-2">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={newLender}
                      onChange={(e) => setNewLender(e.target.value)}
                      id="defaultCheck1"
                    />
                    <label
                      class="form-check-label"
                      for="defaultCheck1"
                      style={{ fontSize: "20px" }}
                    >
                      New Lender
                    </label>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="">Short Name</Form.Label>
                  <Form.Control
                    type="text"
                    id=""
                    aria-describedby="passwordHelpBlock"
                    placeholder="ANZ"
                    value={shortName}
                    onChange={(e) => setShortName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="">Lender Name</Form.Label>
                  <Form.Control
                    type="text"
                    id=""
                    aria-describedby="passwordHelpBlock"
                    placeholder="ANZ Bank Ltd"
                    value={lenderName}
                    onChange={(e) => setLenderName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="">
                  <Form.Label>Icon</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      Choose File
                    </InputGroup.Text>
                    <FormControl
                      placeholder="No file chosen"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
            </Form>
          </Row>
          <Row className="mt-4 p-1">
            <Row>
              <Col>
                <Button>Prev</Button>

                <Button>Next</Button>
              </Col>
              <Col>
                <Link to="/loanpackage">
                  <Button>Quit</Button>
                </Link>
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Lender;
