import { Spinner, Button } from "react-bootstrap";
const Loader = () => {
  return (
    <div>
      <Button
        className="bg-gradient-primary btn btn-primary d-block btn-user w-100 rounded-pill p-3"
        variant="primary"
        disabled
      >
        <Spinner
          className="h-6 w-6"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
      </Button>{" "}
    </div>
  );
};

export default Loader;
