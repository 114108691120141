import _ from "./actionType";

const initialState = {
  // subscribers: { totalCount: "", dataByPage: { "<page number>": [] } },

  selectedFilter: "",
  subscriberIdsByFilter: {
    "<selectedFilter>": {
      totalCount: "",
      dataByPage: {
        "<page number>": [],
      },
      loadingByPage: { "<page number>": false },
      selectedPage: 1,
    },
  },
  searchSubscribersLoading: "",
  errorMessage: "",
  pageSize: 10,

  //array as object id is the primary key
  subscribersList: {},
};

export default function subscribers(state = initialState, action) {
  if (!action.payload) {
    return { ...state };
  }
  const {
    pageSize,
    pageNumber,
    data,
    filter,
    totalCount,
    errorMessage,
    // selectedPage,
  } = action.payload;
  // console.log("action.type");
  // console.log(action.type);
  // console.log("action.payload");
  // console.log(action.payload);
  switch (action.type) {
    case _.searchSubscribers.pending:
      // pageNumber, filter, pageSize;

      if (state.subscriberIdsByFilter[filter]) {
        return {
          ...state,
          searchSubscribersLoading: true,
          subscriberIdsByFilter: {
            ...state.subscriberIdsByFilter,
            [filter]: {
              ...state.subscriberIdsByFilter[filter],
              loadingByPage: { [pageNumber]: true },
            },
          },
        };
      } else {
        return {
          ...state,
          searchSubscribersLoading: true,
          subscriberIdsByFilter: {
            ...state.subscriberIdsByFilter,
            [filter]: {
              ...state.subscriberIdsByFilter[filter],
              loadingByPage: { [pageNumber]: true },
              // totalCount: state.subscriberIdsByFilter[filter].totalCount,
              totalCount: 0,
              dataByPage: {},
              selectedPage: 1,
            },
          },
        };
      }

    case _.searchSubscribers.success:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchSubscribersLoading: false,
        subscriberIdsByFilter: {
          ...state.subscriberIdsByFilter,
          [filter]: {
            ...state.subscriberIdsByFilter[filter],
            totalCount: totalCount,
            dataByPage: {
              ...state.subscriberIdsByFilter[filter].dataByPage,
              [pageNumber]: [...selectPKs(data)],
            },
            loadingByPage: { [pageNumber]: false },
          },
        },
        subscribersList: { ...state.subscribersList, ...arrToObj(data) },
      };

    case _.searchSubscribers.fail:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchSubscribersLoading: false,
        errorMessage: errorMessage,
        subscriberIdsByFilter: {
          [filter]: {
            totalCount: null,
            dataByPage: {
              ...state.subscriberIdsByFilter[filter].dataByPage,
              [pageNumber]: [],
            },
            loadingByPage: { [pageNumber]: false },
          },
        },
      };

    case _.selectPage:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchSubscribersLoading: false,
        subscriberIdsByFilter: {
          ...state.subscriberIdsByFilter,
          [filter]: {
            ...state.subscriberIdsByFilter[filter],
            selectedPage: pageNumber,
          },
        },
      };
    case "updateSubscriber/update/success":
      return {
        ...state,
        subscribersList: {
          ...state.subscribersList,
          [action.payload.updatedSubscriber.id]: {
            ...action.payload.updatedSubscriber,
          },
        },
      };
    default:
      return state;
  }
}

const selectPKs = (arr) => {
  const primaryKey = "id";

  return arr.map((e) => e[primaryKey]);
};

const arrToObj = (arr) => {
  const primaryKey = "id";
  const arrAsObj = {};
  arr.forEach((e) => {
    arrAsObj[e[primaryKey]] = { ...e };
  });

  return arrAsObj;
};
