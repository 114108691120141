import React from "react";
import FormScreen from "../Forms/FormsScreen";

const RecentClosed = () => {
  return (
    <div>
      <FormScreen />
    </div>
  );
};

export default RecentClosed;
