import _ from "./actionType";

const initialState = {
  loading: false,
  token: null,
  user: null,
  isAuthenticated: true,
  errorMessage: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case _.login.pending:
      return {
        loading: true,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    case _.login.fail:
      return {
        loading: false,
        token: null,
        user: null,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
      };
    case _.login.success:
      return {
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case _.logout:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    case _.clearAllErrors:
      return {
        ...state,
        errorMessage: null,
      };

    default:
      return state;
  }
}
