import { Table, Row, Col, Button, Card, Container, Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import * as actions from "../../../store/actions";

const filters = ["Brokers", "Conveyancers", "Buyers Agents", "Accountants"];

const Accounts = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const { subscriberIdsToShow, loadingByPage } = useSelector((store) => store.subscriptions);

  const [selectCheck, setSelectCheck] = useState(false);
  const [selectedFilter, setSeleactedFilter] = useState("Brokers");

  const [selectedSubscriber, setSeleactedSubscriber] = useState(null);

  const currentPage = useSelector((store) => {
    return (
      store.subscriptions.subscriberIdsByFilter[selectedFilter] &&
      store.subscriptions.subscriberIdsByFilter[selectedFilter].selectedPage
    );
  });

  const currentPageLoading = useSelector((store) => {
    return (
      store.subscriptions.subscriberIdsByFilter[selectedFilter] &&
      store.subscriptions.subscriberIdsByFilter[selectedFilter].loadingByPage[
        currentPage
      ]
    );
  });

  const totalDataCount = useSelector((store) => {
    return (
      store.subscriptions.subscriberIdsByFilter[selectedFilter] &&
      store.subscriptions.subscriberIdsByFilter[selectedFilter].totalCount
    );
  });

  const subscribersList = useSelector(
    (store) => store.subscriptions.subscribersList
  );

  const subscriberIdsToShow = useSelector((store) => {
    return (
      store.subscriptions.subscriberIdsByFilter[selectedFilter] &&
      store.subscriptions.subscriberIdsByFilter[selectedFilter].dataByPage[
        currentPage
      ]
    );
  });

  const selectPage = (pageNum) => {
    dispatch(actions.subscriptions.selectPage(selectedFilter, pageNum));
  };

  const pageSize = 10;

  const [totalPagesCount, setTotalPagesCount] = React.useState();

  const checkboxHandler = () => {
    setSelectCheck(!selectCheck);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    !subscriberIdsToShow &&
      !currentPageLoading &&
      dispatch(
        actions.subscriptions.searchSubscribers(currentPage, selectedFilter)
      );
  }, [currentPage, selectedFilter]);

  React.useEffect(() => {
    setTotalPagesCount(
      totalDataCount &&
        (totalDataCount / pageSize).toString().split(".").length > 1
        ? Number((totalDataCount / pageSize).toFixed(0)) + 1
        : totalDataCount / pageSize
    );
  }, [totalDataCount, selectedFilter]);

  console.log({ totalDataCount, totalPagesCount, currentPage });

  return (
    <div className="tab-content">
      <div className="tab-pane active" role="tabpanel" id="tab-1">
        <Row>
          <Col>
            <Card className="card shadow">
              <Card.Header className="py-3">
                <div className="hstack gap-2">
                  {filters.map((e) => (
                    <Button
                      variant={e == selectedFilter ? "primary" : ""}
                      onClick={() => {
                        if (e == selectedFilter) {
                          setSeleactedFilter("");
                        } else {
                          setSeleactedFilter(e);
                        }
                      }}
                    >
                      {e}
                    </Button>
                  ))}
                </div>
              </Card.Header>
              <Card.Body>
                <div
                  className="table-responsive table mt-2"
                  id="dataTable"
                  role="grid"
                  aria-describedby="dataTable_info"
                >
                  <Table className="table-sm text-secondary">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Post code</th>
                        <th>Phone</th>
                        <th>Agency</th>
                        <th>Trial end date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriberIdsToShow &&
                        subscriberIdsToShow.map((id, i) => {
                          const each = subscribersList[id];

                          const trialEndDate = new Date(each.trialEndDate);

                          return (
                            <tr key={i}>
                              <td>
                                <input
                                  type="radio"
                                  id="selectCheck"
                                  onChange={() => {
                                    if (selectedSubscriber == each.id) {
                                    } else {
                                      setSeleactedSubscriber(each.id);
                                    }
                                  }}
                                  checked={selectedSubscriber == each.id}
                                />
                              </td>
                              <td>
                                {each.firstName} {each.lastName}
                              </td>
                              <td>{each.postCode}</td>
                              <td>{each.phone}</td>
                              <td>{each.agency}</td>
                              <td>{`${trialEndDate.getFullYear()}/${
                                trialEndDate.getMonth() + 1
                              }/${trialEndDate.getDate()}`}</td>

                              <td>{each.status}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
                <Row>
                  <Col className="col-md-6 align-self-center">
                    <p
                      id="dataTable_info"
                      className="dataTables_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {(currentPage - 1) * pageSize + 1} to{" "}
                      {currentPage * pageSize} of {totalDataCount}
                    </p>
                  </Col>
                  <Col className="col-md-6">
                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                      <Nav className="pagination">
                        <Nav.Item
                          className={`page-item ${
                            currentPage <= 1 && "disabled"
                          }`}
                          onClick={(e) => {
                            selectPage(currentPage - 1);
                            e.preventDefault();
                          }}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">«</span>
                          </Link>
                        </Nav.Item>
                        {/* {pageNumber} */}
                        {totalPagesCount &&
                          range(1, totalPagesCount).map((pageNum, i) =>
                            i > currentPage - 5 && i < currentPage + 5 ? (
                              <Nav.Item
                                className={`page-item ${
                                  currentPage == pageNum && "active"
                                }`}
                                onClick={() => {
                                  selectPage(pageNum);
                                }}
                              >
                                <Link className="page-link" to="#">
                                  {pageNum}
                                </Link>
                              </Nav.Item>
                            ) : null
                          )}
                        <Nav.Item
                          onClick={(e) => {
                            selectPage(currentPage + 1);
                            e.preventDefault();
                          }}
                          className="page-item"
                        >
                          <Link className="page-link" to="#" aria-label="Next">
                            <span aria-hidden="true">»</span>
                          </Link>
                        </Nav.Item>
                      </Nav>
                    </nav>
                  </Col>
                </Row>{" "}
                <Button disabled={selectedSubscriber == null} className="p-0">
                  <Link
                    className="btn btn-primary btn-sm d-none d-sm-inline-block"
                    role="button"
                    to="/manageAccount"
                  >
                    <i className="fas fa-shopping-cart fa-sm text-white-50"></i>
                    &nbsp;Subscription Upgrade
                  </Link>
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* <FormScreen /> */}
        </Row>
      </div>
    </div>
  );
};
export default Accounts;

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
