export const login = {
  pending: "auth/login/pending",
  success: "auth/login/success",
  fail: "auth/login/fail",
};

export const logout = "auth/logout";

export const clearAllErrors = "auth/clearAllErros";

export default { login, logout, clearAllErrors };
