export const searchLenders = {
    pending: "lenders/searchLenders/pending",
    success: "lenders/searchLenders/success",
    fail: "lenders/searchLenders/fail",
};

export const selectLender = "lenders/selectLender";

export const selectPage = "lenders/selectPage";

export const requestUpdateLender = {
    pending: "lenders/requestUpdateLender/pending",
    success: "lenders/requestUpdateLender/success",
    fail: "lenders/requestUpdateLender/fail",
};

export default {
    searchLenders,
    requestUpdateLender,
    selectLender,
    selectPage,
};